/**
 * used to make a request to arbitrary url when the response value is irrelevant
 */

export default async function emptyFetch(
  url: string,
  options?: RequestInit
): Promise<Response> {
  const response = await fetch(url, options);
  // no response is expected, but reading the response text will suppress "fetch failed" console warnings in chrome when "Log XMLHttpRequests" option is active
  await response.text();
  return response;
}
